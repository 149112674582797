.alert-wrapper {
    position: absolute !important;
    width: unset !important;
    top: 8px;
    left: 50%;
    transform: translate(-50%, 0);
}

.alert-wrapper-mobile {
    --height-of-text-inputs: 48px;

    position: fixed;
    top: var(--height-of-text-inputs);
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
}