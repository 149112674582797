:root {
    --svg-size: 2rem;
}

.compound-icon {
    display: inline-block;
    height: var(--svg-size);
    width: var(--svg-size);
    position: relative;
}

.compound-icon .primary-icon {
    height: 75%;
    width: 75%;
    position: absolute;
    top: 0;
    left: 0;
}

.compound-icon .secondary-icon {
    height: 50%;
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
}

.clickable-compound-icon svg {
    cursor: pointer;
    tab-index: 0;
}
