.tableau-liveboard-container-div {
    position: relative;
    width: calc(100vw - var(--menu-width-collapsed));
    height: 1700px;
    left: calc(-1 * (50vw - 50%) + var(--menu-width-collapsed));
    box-sizing: border-box;
}

#tableau-embed {
    width: 100%;
    height: calc(100% - 36px);
    scale: 1;
    transform-origin: top left;
    margin: 0px;
    min-width: 500px;
    border: none;
    overflow: hidden;
}

.tableau-loader-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tableau-loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #004878;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

:root {
    --menu-width-collapsed: 48px;
    --menu-width-open: 320px;
}
