.date-selector {
    cursor: pointer;
    position: relative;
    height: 56px;
    min-width: 180px;
    width: 180px;
    overflow-x: visible;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px var(--gray-70);
    margin-right: 16px;
}

.date-selector:hover,
.date-selector:focus {
    box-shadow: inset 0 0 0 2px var(--core-blue-40);
}

.date-picker {
    height: 100%;
    width: 320px;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.date-picker label {
    display: inline-block;
    background-color: var(--body-background-color);
    color: var(--gray-80);
    transform: translate(2px, -12px) scale(0.88);
    z-index: 1;
    padding: 0 8px;
    user-select: none;
    cursor: pointer;
}

.date-picker input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    box-shadow: none !important;
    background: none;
    color: transparent;
    text-shadow: 0 0 0 var(--charcoal-70);
}

.date-selector-calendar-icon {
    position: absolute;
    top: 10px;
    right: 8px;
    pointer-events: none;
    color: var(--gray-80);
}
