.manage-marketing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.manage-marketing-label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.manage-marketing-action-tray {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
}

.manage-marketing-preview {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 400px;
    margin-top: 50px;
}

.manage-marketing-preview-image {
    width: 100%;
    border: 5px solid rgba(0, 0, 0, 0.3);
}

.manage-marketing-input-file-hidden {
    display: none;
}