.card-logo-wrapper {
    height: calc(100% - 79px);
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.card-logo {
    max-height: 100%;
    max-width: 100%;
}

.card-logo-placeholder {
    border: 1px solid var(--primary);
    border-radius: 100%;
    padding: 16px;
    height: 5rem;
    width: 5rem;
    color: var(--primary);
}
