.selectable-card.MuiCard-root {
    display: inline-block;
    width: 176px;
    height: 207px;
    margin: 16px;
}

.selectable-card-action-area {
    height: 100%;
}

div.selectable-card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--primary);
    color: white;
    height: 47px;
    padding: 8px;
}
