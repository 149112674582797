body {
    background-color: var(--body-background-color);
}

.main-wrapper {
    height: calc(100vh - var(--height-of-header));
    display: flex;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
    position: relative;
}

main h1 {
    color: var(--primary);
    border-bottom: 1px solid grey;
}

main .page-template-mobile-title {
    font-size: 6vw;
    text-align: center;
}
