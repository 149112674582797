.text-button {
    display: inline;
    margin: 0;
    padding: 0;
    width: auto;
    min-width: unset;
    font-size: inherit;
    font-weight: inherit;
    height: inherit;
    line-height: inherit;
    color: inherit;
}
