@import '~@c1/gravity-react/lib/grv-react.min.css';
@import './assets/styles/colors/index.css';

body {
    background-color: white;
    margin: 0;
    font-family: Optimist, 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.grv-spinner--active{
    z-index: 1;
}