.linked-stepper.MuiStepper-root {
    background-color: transparent;
}

.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
    color: var(--primary);
}

.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-completed {
    color: var(--accent-green);
}

.step-label-link {
    color: var(--charcoal-50);
    text-decoration: none;
}