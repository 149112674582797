:root {
    --red-10: #FCD5D1;
    --red-20: #F8ADA4;
    --red-30: #EC837A;
    --red-40: #E05752;
    --red-50: #CC2427;
    --red-60: #BA2629;
    --red-70: #A3282B;

    --light-blue-10: #C0E7F3;
    --light-blue-20: #9FDBEF;
    --light-blue-30: #78D0EB;
    --light-blue-40: #4BC7E7;
    --light-blue-50: #00C0E5;
    --light-blue-60: #00AFD4;
    --light-blue-70: #00A3C4;
    --light-blue-80: #007F9B;

    --yellow-10: #FFF1D0;
    --yellow-20: #FFE7A7;
    --yellow-30: #FEDC7D;
    --yellow-40: #FBD44D;
    --yellow-50: #F9C606;
    --yellow-60: #F1BA0D;
    --yellow-70: #E8AD11;

    --green-10: #DAE8D8;
    --green-20: #BAD4B4;
    --green-30: #9AC497;
    --green-40: #7BB475;
    --green-50: #59A545;
    --green-60: #309042;
    --green-70: #008140;

    --accent-red: var(--red-50);
    --accent-light-blue: var(--light-blue-50);
    --accent-yellow: var(--yellow-50);
    --accent-green: var(--green-50);
}
