footer {
    flex-shrink: 0;
    margin-top: 16px;
    background-color: var(--gray-30);
    color: var(--gray-100);
}

.footer-disclaimer {
    font-size: 0.9rem;
}
