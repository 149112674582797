.loading-indicator {
    text-align: center;
}

.loading-indicator-icon {
    color: var(--primary);
    animation: spin 2s ease-in-out infinite;
    padding: 8px;
    height: 80px;
    width: 80px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
