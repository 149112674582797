.banner-alert {
    z-index: 11;
    text-align: center;
}

.grv-alert__message {
    float: unset !important;
    padding-right: 120px !important;
}
.banner-alert-container {
    position: fixed;
    z-index: 11;
}