.report-selection {
    margin-top: 16px;
}

.report-selection-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.report-date-selector-width {
    width: 50%;
}
