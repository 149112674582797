.manage-partner-container {
    display: block;
    flex-direction: column;
    align-items: left;
    margin-left: -22px;
}

.manage-partner-label {
    display: block;
    flex-direction: column;
    align-items: center;
}

.manage-partner-action-tray {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    padding: 5px;
}

.manage-partner-preview {
    display: block;
    flex-direction: column;
    align-items: center;

    max-width: 400px;
    margin-top: 12px;
}

.manage-partner-preview-image {
    width: 100%;
    border: 5px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
}

.manage-partner-input-file-hidden {
    display: block;
}

.manage-partner-validation {
    color: red;
}