.walmart-dispute-message-title {
    padding-left: 2em;
}

.walmart-disputes-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 2em;
    margin-top: 8px;
}

.walmart-disputes-form::after {
    flex: auto;
}

.walmart-disputes-form > * {
    width: 24%;
    margin-right: 8px;
}

.walmart-disputes-form > .form-action-tray.action-tray {
    width: 100%;
}

.walmart-dispute-divider {
    width: 100%;
    display: flex;
    align-items: center;
}

.walmart-dispute-divider > *:first-child {
    width: 100%;
}

.walmart-dispute-reason {
    width: 49%;
}

.another-dispute-button {
    width: 100%;
}