.user-role-checkbox-tray {
    padding: 16px 0px;
}

.user-role-checkbox-tray > .grv-form-field {
    margin-left: 16px;
    margin-bottom: 16px;
}

.radio-button-group {
    flex-direction: column;
}

.radio-button-group > .grv-form-field {
    margin-left: 16px;
    margin-bottom: 16px;
}