.home-page-tile p {
    color: var(--charcoal-60);
    text-align: justify;
}

.home-page-card-links-desktop {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 16px;
    flex-wrap: wrap;
}

.home-page-card-links-mobile {
    display: flex;
    flex-direction: column;
}

.home-page-promotional-image-link {
    text-decoration: none;
    margin-bottom:10px;
}

.home-page-promotional-image-link-disabled {
    filter: grayscale(100%);
    opacity: 0.5;
    cursor: not-allowed;
}
