:root {
    --height-of-header-internals: 40px;
    --padding-around-header: 8px;
    --height-of-header: calc(var(--height-of-header-internals) + 2 * var(--padding-around-header));
}

header {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: var(--core-blue-50);
    border-bottom: 1px solid var(--gray-30);
    box-shadow: 1px 1px 2px var(--gray-30);
    padding: var(--padding-around-header);
}

header + * {
    margin-top: var(--height-of-header);
}

.header-left > *, .header-right > * {
    display: inline-block;
    vertical-align: middle;
}

.menu-button {
    color: white;
}

.header-link {
    height: var(--height-of-header-internals);
    margin-left: 16px;
    display: inline-flex;
    text-decoration: none;
}

.header-title {
    line-height: var(--height-of-header-internals);
    font-size: 24px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    padding-left: 16px;
    margin-left: 16px;
    border-left: 1px solid var(--gray-70);
}

.header-left-mobile {
    width: 20%;
}

.header-middle {
    width: 100%;
}

.header-right {
    color: white;
    font-size: 20px;
    width: 20%;
    margin-right: 1rem;
    display: inline-flex;
}

.header-user-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: right;
}

.header-myaccount-link {
    color: white;
    text-decoration: none;
    display: flex;
}