fieldset.radio-button-group {
    display: flex;
    margin-bottom: 24px;
}

.radio-button-group legend {
    display: block;
    color: #141414;
    font-size: 14px;
    font-weight: unset;
    padding-bottom: 8px;
    margin-bottom: 0;
}

.radio-button-group .grv-form-field {
    margin-bottom: 0;
    margin-right: 24px;
}

.radio-button-group .grv-radiobutton__helper--error {
    display: none;
}

.radio-button-group .grv-radiobutton__label--disabled {
    cursor: not-allowed;
}
