.report-viewer-table-wrapper {
    max-width: 100%;
    overflow-x: scroll;
}

.report-viewer-table .grv-table__head .grv-table__header {
    border-top: 1px solid #d4d4d4;
}

.report-viewer-table.grv-table th,
.report-viewer-table.grv-table td {
    border-right: 1px solid #d4d4d4;
}

.report-viewer-table.grv-table th:first-of-type {
    border-left: 1px solid #d4d4d4;
    padding-left: 8px;
    width: 40px;
}
