.right-justify {
    justify-content: right;
    display: flex;
}

.button-margin {
    margin: 2px;
}

.display-flex {
    display: flex;
}