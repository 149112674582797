:root {
    --icon-container-width: calc(2 * var(--svg-size));
}

.multi-item-text-input-item {
    --height-of-text-inputs: 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.multi-item-text-input {
    width: calc(100% - var(--icon-container-width));
    margin-bottom: 0;
}

.remove-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--height-of-text-inputs);
    width: var(--icon-container-width);
    color: var(--accent-red);
}

.multi-item-text-input-add-button {
    margin-top : 8px;
}

