:root {
    --width-of-nav-closed: 48px;
    --width-of-nav-open: 320px;
}

.navigation-pane {
    transition: 0.5s;
    position: fixed;
    top: var(--height-of-header);
    left: 0;
    height: calc(100% - var(--height-of-header));
    width: var(--width-of-nav-closed);
    background-color: white;
    border-right: 1px solid var(--core-blue-50);
    box-sizing: border-box;
    overflow-x: hidden;
    z-index: 10;
}

.navigation-pane.open {
    width: var(--width-of-nav-open);
}

.navigation-pane ~ * {
    transition: 0.5s;
    position: relative;
    left: var(--width-of-nav-closed);
    width: calc(100% - var(--width-of-nav-closed));
}

.navigation-pane.open ~ * {
    left: var(--width-of-nav-open);
    width: calc(100% - var(--width-of-nav-open));
}
