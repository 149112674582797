.action-tray {
    display: flex;
    justify-content: flex-end;
}

.action-tray > * {
    margin-right: 8px;
}

.action-tray > *:last-child {
    margin-right: 0;
}
