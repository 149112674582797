.base-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-body {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 2px solid var(--charcoal-20);
    border-radius: 4px;
    padding-top: 0;
}

.modal-title {
    flex-shrink: 0;
}

.modal-description {
    overflow: scroll;
    flex-grow: 1;
    position: relative;
}

.modal-action-tray {
    flex-shrink: 0;
    margin-top: 8px;
}
