.navigation-pane .nav-menu-items {
    width: var(--width-of-nav-open);
}

.navigation-menu-mobile .nav-menu-items {
    width: 100%;
    padding: 2rem;
}

.nav-menu-item {
    text-decoration: none;
    display: block;
}

.nav-menu-item .grv-accordion__title:after {
    right: 16px;
}

.nav-menu-item-link .grv-accordion__title:after {
    display: none;
}

.nav-menu-items .grv-accordion__title {
    color: var(--charcoal-50);
    display: flex;
    align-items: center;
    padding: 8px;
}

.nav-menu-items .grv-accordion__section .grv-accordion__content {
    padding: 0;
}

.nav-menu-items .grv-accordion__item:first-child {
    border-top: 1px solid #d4d4d4;
}

.nav-menu-item-page-name {
    margin-left: 16px;
}

.mobile-menu .grv-accordion__title {
    padding: 15px 0px;
}

.mobile-menu .nav-sub-menu-item {
    margin-left: 16px;
}
