:root {
    --core-blue-10: #B3CDDE;
    --core-blue-20: #8AB0C7;
    --core-blue-30: #598AAA;
    --core-blue-40: #255F82;
    --core-blue-50: #013D5B;
    --core-blue-60: #10253F;
    --core-blue-70: #00132B;

    --primary: var(--core-blue-50);
}
