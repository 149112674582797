.login-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-tile p,span {
    text-align: center;
}

.login-form {
    width: 100%;
    max-width: 400px;
}