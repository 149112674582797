.dashboard-list-table th:not(:last-child) {
    min-width: 0px;
}

.dashboard-list-table th:last-child {
    min-width: 0px;
}

.thoughtsport-dashboard-list-card-view {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.thoughtsport-dashboard-form-row{
    margin-bottom: 10px;
    margin-left: -20px;
    margin-right: -20px;
}

.dashboard-title {
    max-height: 48px;
    padding: 3px;
    height: 42px;
    align-items: center;
    display: flex;
}

.dashboard-tile {
    max-width: 500px;
    min-width: 310px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    margin: auto auto 18px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dashboard-description {
    padding: 5px;
}

.dashboard-action {
    flex-grow: 1;
}

.dashboard-header {
    display: flex;
    min-width: calc( 100% - 90px);
}

.dashboard-tile-body {
    display: flex;
}