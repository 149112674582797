.liveboard-container-div{
    padding: -10px ;
    flex: 1;
    margin-top: 10;
    margin-left: -28px ;
    margin-right: -28px ;
    position: relative;
    height: 100%;
    width: auto;
}
#thoughtspot-embed {
    margin: 0px;
    min-width: 500px;
}