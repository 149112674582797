.checkbox-group {
    padding: 8px 0;
    margin-bottom: 0;
}

.checkbox-group:nth-of-type(even) {
    background-color: var(--gray-20);
}

fieldset.checkbox-group-fieldset {
    margin-bottom: 0;
}

fieldset.checkbox-group-fieldset::after {
    content: '';
    clear: both;
    display: table;
}

.checkbox-group legend {
    float: left;
    font-size: 16px;
    max-width: 40%;
    padding-bottom: 0;
}

.checkbox-tray {
    float: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}

.checkbox-group .grv-form-field {
    margin-bottom: 0;
    margin-right: 24px;
}

.remove-report-category-icon {
    display: flex;
    justify-content: center;
    width: var(--icon-container-width);
    color: var(--accent-red);
}

.remove-report-category-icon svg {
    cursor: pointer;
}

.manage-report-category-alerts {
    display: flex;
    justify-content: center;
    width: var(--icon-container-width);
    color: var(--core-blue-60);
    margin-right: 10px;
}