.validate-input-line {
    display: flex;
    align-items: center;
    overflow: visible;
}

.validate-button-line {
    display: flex;
    align-items: center;
}

.validation-message-success {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: var(--accent-green)
}

.validation-message-failure {
    display: flex;
    align-items: center;
    color: var(--accent-red)
}

.validation-icon {
    width: 48px;
    height: 48px;
}

.register-text {
    font-size: 15px;
    margin-bottom: 15px;
}