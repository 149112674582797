.rstudio-liveboard-container-div{
    flex: 1;
    margin-top: 10;
    margin-left: -28px ;
    margin-right: -28px ;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
}
#rstudio-embed {
    height: 100%;
    width: 100%;
    margin: 0px;
    min-width: 500px;
    border: none;
}

.rstudio-loader-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rstudio-loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #004878;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}