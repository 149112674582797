#quicksight-page-body {
    height: calc(100% - 80px);
}
.quicksight-container{
    padding: -10px ;
    flex: 1;
    margin-top: 10;
    margin-left: -20px ;
    margin-right: -20px ;
    position: relative;
    height: calc(100% - 48px);
    width: auto;
}
