:root {
    --svg-size: 2rem;
}

.icon {
    display: inline-block;
    height: var(--svg-size);
    width: var(--svg-size);
    color: inherit;
}

.icon svg {
    height: 100%;
    width: 100%;
    vertical-align: unset !important;
}

.functional-icon {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
}
