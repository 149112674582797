.admin-table .grv-table__header {
    vertical-align: bottom;
}

.admin-table .grv-table__cell {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pagination-button {
    background: grey;
    min-width: 40px;
    max-height: 30px;
    line-height: 30px;
    margin: 0px 2px;
}
.pagination-button:hover {
    background: black;
}
