.tile {
    padding: 0px 24px;
    margin: 16px auto;
}

.tile .grv-card {
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3);
}

.tile .grv-card__media {
    background-position: 50% 25%;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}