.dashboard-admin-table th {
    min-width: 200px;
}

.dashboard-admin-table .grv-table__cell:nth-child(4) {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;

    direction: rtl;
    text-align: left;
}