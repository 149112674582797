:root {
    --icon-container-width: calc(2 * var(--svg-size));
}

.report-category-dropdown-select-wrapper {
    --height-of-text-inputs: 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

div.report-category-dropdown-select {
    width: calc(100% - var(--icon-container-width));
    margin-bottom: 0;
}

.add-report-category-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--height-of-text-inputs);
    width: var(--icon-container-width);
    color: var(--accent-green);
}