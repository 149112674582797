:root {
    --gray-10: #F4F4F4;
    --gray-20: #EBEBEB;
    --gray-30: #E4E5E5;
    --gray-40: #D7D7D7;
    --gray-50: #CECFCD;
    --gray-60: #C3C4C2;
    --gray-70: #AFB0AF;
    --gray-80: #7D7D7D;
    --gray-90: #676868;
    --gray-100: #515252;

    --charcoal-10: #BCBFC7;
    --charcoal-20: #8B939E;
    --charcoal-30: #606A77;
    --charcoal-40: #3E4B59;
    --charcoal-50: #1C2B39;
    --charcoal-60: #0E1D2B;
    --charcoal-70: #141414;
}
