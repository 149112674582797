.external-link {
    text-decoration: underline;
    cursor: pointer;
    color: -webkit-link;
    color: -moz-hyperlinktext;
}

.external-link:active {
    color: -webkit-activelink;
    color: -moz-activehyperlinktext;
}
